<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-card>
            <v-form
                v-model="formValid"
                ref="form"
            >
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}} 
                </v-card-title>
                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    
                    <v-select
                        v-for="itm in valoresCampos"
                        :key="itm.id"
                        v-model.number="vals[itm.campo]"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala[itm.campo]"
                        :label="itm.label"
                        :rules="[numberRule]"
                    />

                    <v-text-field
                        v-model="observaciones"
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>
                    
                    <v-expand-transition>
                        <div v-if="formValid" class="text-center">
                            <div class="title">Puntaje: <span>{{sumaValoracion}}</span></div>
                            <div class="font-weight-black" :style="'color: ' + textoValoracion.color">{{textoValoracion.text}}</div>
                        </div>
                    </v-expand-transition>

                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        color="success"
                        :loading="loading"
                        :disabled="!formValid"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        formValid:false,
        loading:false,
        observaciones:'',
        vals:{
            torax :null,
            ic:null,
            xifo:null,
            aleteo:null,
            quejido:null,
            
        },

        valoresCampos:[
            {id:0, label:'Mov. toracoabdominales', campo:'torax'},
            {id:1, label:'Tiraje I.C.', campo:'ic'},
            {id:2, label:'Retrac. xifoidea', campo:'xifo'},
            {id:3, label:'Aleteo nasal', campo:'aleteo'},
            {id:4, label:'Quejido espiratorio', campo:'quejido'},
        ],

        itemsEscala:{
            torax:[
                {value:0, text: 'Regulares, rítmicos'},
                {value:1, text: 'Torax inmovil, abdomen en mov.'},
                {value:2, text: 'Disociación toracoabdominal'},
                
            ],
            ic:[
                {value:0, text: 'No'},
                {value:1, text: 'Leve'},
                {value:2, text: 'Intensa contante'},
                
            ],
            xifo:[
                {value:0, text: 'No'},
                {value:1, text: 'Leve'},
                {value:2, text: 'Intensa'},
            ],
            aleteo:[
                {value:0, text: 'No'},
                {value:1, text: 'Leve'},
                {value:2, text: 'Intensa'},
            ],
            quejido:[
                {value:0, text: 'No'},
                {value:1, text: 'Audible con estestos'},
                {value:2, text: 'Audible a distancia'},
            ],
        },
        numberRule: v  => {
            if (!isNaN(parseFloat(v)) && v >= 0) return true;
            return 'Requerido';
        },
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        },
        sumaValoracion(){
            let text = ''
            if (this.formValid){
                return Object.values(this.vals).reduce((a,b)=>{return a+b})
            }
            return text
        },
        textoValoracion(){

            let texto = ''
            let color = ''
            if (this.sumaValoracion){
                let esteValor = this.sumaValoracion
                if (esteValor == 0){
                    texto = 'Sin dific.'
                    color = 'green'
                }
                
                if (esteValor > 0 && esteValor <= 3){
                    texto = 'Dific. leve'
                    color = 'blue'
                }

                if (esteValor > 3 && esteValor <= 6){
                    texto = 'Dific. moderada'
                    color = 'orange'
                }
                if (esteValor > 6){
                    texto = 'Dific. severa'
                    color = 'red'
                }
            }
            return {text:texto, color:color}
        },
    },

    methods:{
        cancela(){
            this.dialog = false
        },
        async agrega(){

            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'silverman',
                valor: JSON.stringify(this.vals),
                internamiento:this.internamiento,
                observaciones: this.observaciones
            }
            
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                this.loading=false
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.dialog = false

                } else {
                    console.log(dt.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
            
        },
    },
    watch:{
        dialog(v){
            if(!v){
                this.$refs.form.reset()
            }
        }
    },
    
}
</script>

<style>

</style>